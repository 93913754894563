<template>
  <div class="">
    <van-form @submit="onSubmit" colon label-width="7em" label-align="right">
      <van-field v-model="form.city" label="城市" placeholder="请填写城市省市区"
        :rules="[{ required: true, message: '请填写用户名' }]" />
      <van-field v-model="form.userName" label="联系人名称" placeholder="请填写联系人名称"
        :rules="[{ required: true, message: '请填写用户名' }]" />
      <van-field v-model="form.userPhone" label="联系电话号码" placeholder="请填写联系电话号码"
        :rules="[{ required: true, message: '请填写用户名' }]">
        <template #button>
          <van-button v-if="num > 0 && num !== 60" size="small" type="default" plain>{{ num }}秒后可重新获取</van-button>
          <van-button v-else size="small" type="default" plain @click="sendSms()">发送验证码</van-button>
        </template>
      </van-field>

      <van-field v-model="form.code" label="短信验证码" placeholder="请填写短信验证码"
        :rules="[{ required: true, message: '请填写短信验证码' }]" />
      <van-button class="submitBut" round block type="info" native-type="submit">提交</van-button>
    </van-form>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'coopSubmit',
  data() {
    return {
      form: {
        trxCode: 'XF180',
        type: ''
      },
      num: 60
    }
  },
  computed: {
    ...mapState('user', ['agentId']),
  },
  created() {
    this.form.type = this.$route.query.type
    this.getTime()
  },
  methods: {
    // 提交
    onSubmit() {
      mallApi(this.form).then(res => {
        if (res.rspCode === '0000') {
          this.$toast.success('操作成功')
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },

    // 发送短信验证码
    sendSms() {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.form.userPhone)) {
        this.$toast('请输入正确的手机号码')
        return
      }

      mallApi({
        trxCode: 'XF172',
        phone: this.form.userPhone,
        agentId: this.agentId,
      }).then((res) => {
        if (res.trxCode) {
          sessionStorage.setItem('time', Date.parse(new Date()) / 1000)
          this.$toast('已发送')
          this.getTime()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    getTime() {
      var date2 = Date.parse(new Date()) / 1000
      var time = date2 - sessionStorage.getItem('time')
      this.num = 60 - time
      if (this.num > 0) {
        setTimeout(this.getTime, 1000)
      }
    },
  }
}
</script>

<style lang='less' scoped>
.submitBut {
  border: none;
  width: 90%;
  margin: 10vw auto;
}
</style>